import React from 'react'
import Calendar from '../Calendar'
import { Field } from 'react-final-form';
import { renderFormatMsg } from '../../shared/funs';

const ActiveFromAndToDate = ({ readOnly = false, className }) => {
  return (
    <div className="row">
      <Field name="activeFrom">
        {({ input, meta }) => (
          <div className="frm_grp form-group" style={{ flex: "0 0 20%" }}>
            <lable>{renderFormatMsg("ActiveFrom", "ActiveFrom")}</lable>
            <Calendar disabled={readOnly} className={className} placeholderText="Active from" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
      <Field name="activeTo">
        {({ input, meta }) => (
          <div className="frm_grp form-group" style={{ flex: "0 0 20%" }}>
            <lable> {renderFormatMsg("ActiveTo", "activeTo")} </lable>
            <Calendar disabled={readOnly} className={className} placeholderText="Active to" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
    </div>
  )
}

export default ActiveFromAndToDate
