import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import SearchIcon from "../../images/search.svg";

export default function DropDownCustom({ isSearchable = false, classes = "", removeDownArrow = true, name = false, selectedData, dropdownData = false, handleData = false }) {
  const [filtered, setFiltered] = useState(dropdownData)
  useEffect(() => {
    setFiltered(dropdownData)
  }, [dropdownData])
  const filterData = (search) => {
    if (search !== "") {
      let temp = [];
      dropdownData.map(o => {
        if (o[name].toLowerCase().includes(search.toLowerCase())) { temp.push(o); }
      });
      setFiltered(temp)
    } else {
      setFiltered(dropdownData)
    }
  }

  return (
    <Dropdown alignRight>
      <Dropdown.Toggle variant="Secondary" className={classes} id="dropdown-basic">{selectedData}
        {removeDownArrow && <i className="icon-down-arrow"></i>}
      </Dropdown.Toggle>{name !== false && dropdownData !== false && handleData !== false &&
        <Dropdown.Menu style={{ paddingTop: 0, minWidth: "305px" }}>
          {isSearchable &&
            <form class="search-boxs undefined w-100">
              <input placeholder="Search" type="text" className="form-control mb-2" autoFocus name="search" onChange={(e) => filterData(e.target.value)} />
              <button type="button" class="btn btn-light search_button">
                <img src={SearchIcon} alt="SearchIcon" />
              </button>
            </form>
          }
          {
            dropdownData.length > 0 && filtered.map((data, i) => <Dropdown.Item key={i} onClick={() => handleData(data)}>{data[name]}</Dropdown.Item>)
          }
        </Dropdown.Menu>}
    </Dropdown >
  )
}
