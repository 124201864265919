import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastError, toastSuccess } from '../../../shared/funs';
import { Link } from 'react-router-dom';
import BackIcon from "../../../images/back-icon.svg";
import { addOutletCategories, updateOutletCategories, getOutletCategories } from '../../../services/toolsAndSettingsPagesApi';
import Error from '../../../components/Error/Error';

const HandleStoreCategory = (props) => {
    const [outletCategory, setOutletCategory] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    var heading = renderFormatMsg("HandleStore.Add.Heading", "Add Store Category");
    var values = {
        customerOutletCategoryId: "",
        name: ""
    }

    if (props.location.pathname.includes("Edit")) {
        heading = renderFormatMsg("HandleStore.Edit.Heading", "Edit Store Category");
        values = {
            customerOutletCategoryId: get(outletCategory, "customerOutletCategoryId"),
            name: get(outletCategory, "name")
        }
    }

    useEffect(() => {
        if (props.location.pathname.includes("Edit")) {
            getOutletCategories().then(res => {
                let category = find(res.outletCategories, (data) => data.outletCategoryId === props.match.params.outletCategoryId)
                setOutletCategory(category && category)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                setError(err)
            })
        }

    }, [])

    const handleAddForm = async (data) => {
        let object = {
            "customerOutletCategoryId": data.customerOutletCategoryId,
            "name": data.name
        }
        await addOutletCategories(object).then(res => {
            toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
            props.history.push("/storeCategory")
        }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    }

    const handleEditForm = async (data) => {
        const object = {
            "outletCategoryId": props.match.params.outletCategoryId,
            "customerOutletCategoryId": data.customerOutletCategoryId,
            "name": data.name
        }
        await updateOutletCategories(object).then(res => {
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
            props.history.push("/storeCategory")
        }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
    }
    const validation = (data) => {
        let errors = {}

        if (!data.customerOutletCategoryId || data.customerOutletCategoryId === "") {
            errors.customerOutletCategoryId = "Required";
        }
        if (!data.name || data.name === "") {
            errors.name = "Required";
        }
        return errors
    }
    return (
        <div className="main-content">
            <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                <div className="heading">
                    <Link className="back-btn" to="/storeCategory"><img src={BackIcon} alt="BackIcon" /></Link>
                    <span>{heading}</span>
                </div>
            </div>
            <div className="card p-3">
                {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm} initialValues={values} validate={validation}>
                    {({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="align-items-end">
                                <div className="form">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Field name="customerOutletCategoryId">
                                                {({ input, meta }) => (
                                                    <div className="form-group mb-0">
                                                        <lable>
                                                            {renderFormatMsg("HandleStore.StoreCategoryId", "Store Category ID")}
                                                        </lable>
                                                        <input
                                                            {...input}
                                                            type="text"
                                                            placeholder="Store Category ID"
                                                            className="form-control"
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <span>{meta.error}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-lg-6">
                                            <Field name="name">
                                                {({ input, meta }) => (
                                                    <div className="form-group mb-0">
                                                        <lable>
                                                            {renderFormatMsg("HandleStore.CategoryName", "Category Name")}
                                                        </lable>
                                                        <input
                                                            {...input}
                                                            type="text"
                                                            placeholder="Category Name"
                                                            className="form-control"
                                                        />
                                                        {meta.error && meta.touched && (
                                                            <span>{meta.error}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end mt-2 row">
                                    <div className="col-12 col-lg-auto">
                                        <div className="submit_btn">
                                            <button
                                                type="submit"
                                                disabled={submitting}
                                                className="btn btn-primary"
                                            >
                                                {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>}
            </div>
        </div>
    )
}

export default React.memo(HandleStoreCategory)
