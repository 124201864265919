import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastSuccess, toastError, permissionErrorCheck, selectStyle } from '../../shared/funs';
import { Link } from 'react-router-dom';
import BackIcon from "../../images/back-icon.svg";
import { DATE_FORMAT } from '../../constant';
import { getPromotion, addPromotion, updatePromotion } from '../../services/sidebarApi';
import moment from 'moment';
import { getPromotionType } from '../../services/toolsAndSettingsPagesApi';
import Error from '../../components/Error/Error';
import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';

const HandlePromotionType = (props) => {
  const [promotionType, setPromotionType] = useState([])
  const [promotion, setPromotions] = useState(null)
  const [error, setError] = useState(false)

  var heading = renderFormatMsg("AddPromotion", "Add Promotion");
  var values = {
    name: "",
    promotionTypeId: "",
    scope: "",
    initialCoefficient: "",
    coefficient: "",
    activeFrom: "",
    activeTo: "",
  }

  if (props.location.pathname.includes("Edit")) {
    heading = renderFormatMsg("Edit Promotion", "Edit Promotion");
    values = {
      name: get(promotion, "name"),
      promotionTypeId: get(promotion, "promotionType.promotionTypeId"),
      scope: get(promotion, "scope"),
      initialCoefficient: get(promotion, "initialCoefficient"),
      coefficient: get(promotion, "coefficient"),
      activeFrom: get(promotion, "activeFrom"),
      activeTo: get(promotion, "activeTo")
    }
  }

  useEffect(() => {
    getPromotionType().then(res => setPromotionType(res.promotionTypes)).catch(err => {
      setError(permissionErrorCheck(err))
    })

    getPromotion().then(res => {
      let promotion = find(res.promotions, (data) => data.promotionId === props.match.params.promotionId);
      setPromotions(promotion)
    }).catch(err => {
      setError(permissionErrorCheck(err))
    })
  }, [])

  const handleForm = async (data) => {
    let object = {
      "promotionTypeId": data.promotionTypeId,
      "name": data.name,
      "initialCoefficient": data.initialCoefficient,
      "coefficient": data.coefficient,
      "scope": data.scope,
      "activeFrom": moment(data.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(data.activeTo).format(DATE_FORMAT)
    }

    if (props.location.pathname.includes("Add")) {
      addPromotion(object).then(resp => {
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/promotion")
      }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    } else {
      object.promotionId = props.match.params.promotionId
      updatePromotion(object).then(resp => {
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        props.history.push("/promotion")
      }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
    }
  }

  const validation = (data) => {
    let errors = {}
    var patt = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.promotionTypeId || data.promotionTypeId === "") {
      errors.promotionTypeId = "Required";
    }
    if (!data.scope || data.scope === "") {
      errors.scope = "Required";
    }
    if (data && typeof data.initialCoefficient !== "undefined") {
      if (patt.test(data.initialCoefficient)) {
        errors.initialCoefficient = undefined;
      } else {
        errors.initialCoefficient = "Invalid Input";
      }
    } else {
      errors.initialCoefficient = "Required";
    }
    if (data && typeof data.coefficient !== "undefined") {
      if (patt.test(data.coefficient)) {
        errors.coefficient = undefined;
      } else {
        errors.coefficient = "Invalid Input";
      }
    } else {
      errors.coefficient = "Required";
    }
    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    return errors
  }
  return (
    <div className="main-content">
      <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/promotion"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div>
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable>{renderFormatMsg("PromotionName", "Promotion Name")} </lable>
                            <input {...input} type="text" placeholder="Promotion Name" className="form-control" />
                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-6">
                      <Field name="promotionTypeId" component="select" options={promotionType}>
                        {({ input, meta, options }) => (
                          <div className="form-group">
                            <lable>{renderFormatMsg("PromotionType", "Promotion Type")} </lable>
                            <select style={selectStyle(input)} defaultValue={input.value} className="form-control" onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select one</option>
                              {options && options.map((x) => {
                                return (<option selected={input.value === x.promotionTypeId} key={x.promotionTypeId} value={x.promotionTypeId}>{x.name}
                                </option>
                                )
                              })}
                            </select>
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="scope">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("Scope", "Scope")} </lable>
                            <input {...input} type="text" placeholder="Scope" className="form-control" />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-6"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="initialCoefficient">
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <lable> {renderFormatMsg("InitialCoefficient", "Initial Coefficient")}</lable>
                            <input {...input} type="text" placeholder="Base Coefficient" className="form-control" />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        }
                      </Field>
                    </div>
                    <div className="col-lg-6">
                      <Field name="coefficient">
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <lable> {renderFormatMsg("CurrentCoefficient", "Current Coefficient")}</lable>
                            <input {...input} type="text" placeholder="Current Coefficient" className="form-control" />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        }
                      </Field>
                    </div>
                  </div>
                  <ActiveFromAndToDate />
                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandlePromotionType)
