import React from 'react'
import { Form, FormControl, Button } from 'react-bootstrap';
import SearchIcon from "../../images/search.svg";

export default function SearchBox({ searchHandler, className }) {
  return (
    <Form className={`search-boxs ${className}`}>
      <FormControl
        type="text"
        placeholder="Search"
        onChange={searchHandler}
      />
      <Button variant="light">
        <img src={SearchIcon} alt="SearchIcon" />
      </Button>
    </Form>
  )
}
