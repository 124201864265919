import React, { createContext, useReducer } from "react";
let AppContext;
const { Provider, Consumer } = (AppContext = createContext());
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";

let initialLanguage = localStorage.getItem("language");

export const initialState = {
  language: initialLanguage ? initialLanguage : "en",
  userDetails: null,
};

export default function reducer(state, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      let language = action.payload;
      localStorage.setItem("language", language);
      return { ...state, language };

    case SET_USER_DETAILS:
      let userDetails = action.payload;
      return { ...state, userDetails };

    default:
      return state;
  }
}

function AppProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleLangauge = (data) => {
    dispatch({ type: SET_LANGUAGE, payload: data });
  };
  const setUserDetails = (data) => {
    dispatch({ type: SET_USER_DETAILS, payload: data });
  };

  return (
    <Provider
      value={{
        ...state,
        ...props,
        handleLangauge,
        setUserDetails,
      }}
    >
      {children}
    </Provider>
  );
}

export { AppProvider, Consumer as AppConsumer, AppContext };
