import React, { useState } from 'react'
import { Field } from 'react-final-form';
import { renderFormatMsg } from '../../shared/funs';
import ReactTable from '../ReactTable/ReactTable';
import Modal from "../Modal/index"
import CustomButton from "../Buttons/CustomButton"

const BrandInformation = ({ values, form }) => {
  // eslint-disable-next-line
  const [brands, setBrands] = useState(values.brands ? values.brands : [])
  const [openModal, setOpenModal] = useState(false)

  const columns = [
    { Header: (<div style={{ textAlign: "left" }}> {renderFormatMsg("BrandName", "Brand Name")} </div>), accessor: 'brand', style: { justifyContent: "flex-start" } }
  ]

  const handleBrand = (input) => {
    brands.push({ [input.name]: input.value })
    setOpenModal(!openModal)
    input.onChange("")
    form.change("brands", brands)
  }
  const addButtonHandler = () => setOpenModal(!openModal)

  return (
    <div className="card">
      <div className="card-header">
        <CustomButton onClick={addButtonHandler} id="AddBrand" defaultText="Add Brand" />
      </div>
      <ReactTable data={brands} columns={columns} showPagination={false} />
      <Modal openModal={openModal} setOpenModal={setOpenModal} heading={renderFormatMsg("AddNewBrand", "Add New Brand")} classes="">
        <div className="col-12">
          <Field name="brand">
            {({ input, meta }) => (
              <div className="form-group">
                <lable>
                  {renderFormatMsg("BrandName", "Brand Name")}
                </lable>
                <input {...input} name="brand" type="text" placeholder="Brand Name" className="form-control"
                  onChange={(e) => input.onChange(e)} />
                {meta.error && meta.touched && (<span>{meta.error}</span>)}
                <div class="btn_pop p-0">
                  <button className="btn_white" onClick={() => setOpenModal(!openModal)}>Cancel</button>
                  <button className="btn_grey" onClick={() => handleBrand(input)}> {renderFormatMsg("AddBrand", "Add Brand")}</button></div>
              </div>
            )}
          </Field>
        </div>
      </Modal>
    </div>
  )
}
export default React.memo(BrandInformation)
