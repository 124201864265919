import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastError, toastSuccess, selectStyle } from '../../../shared/funs';
import { Link } from 'react-router-dom';
import BackIcon from "../../../images/back-icon.svg";
import { getCity, getTransportTypes, getTransportTimesCities, addTransportTimesCity, updateTransportTimesCity } from '../../../services/toolsAndSettingsPagesApi';

const HandleTransportTimePerCity = (props) => {
  const [transportTimesCity, setTransportTimesCity] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([])
  const [city, setCity] = useState([])
  const [transportType, setTransportType] = useState([])

  var heading = renderFormatMsg("AddTransportTimePerCity", "Add Transport Time Per City");
  var values = {
    cityId: "",
    transportTypeId: "",
    transportTime: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = renderFormatMsg("EditTransportTimePerCity", "Edit Transport Time Per City");
    values = {
      cityId: get(transportTimesCity, "citeName"),
      transportTypeId: get(transportTimesCity, "transportTypeId"),
      transportTime: get(transportTimesCity, "transportTime"),
    }
  }

  useEffect(() => {
    getTransportTimesCities().then(res => {
      let transportTimesCity = find(res.transportTimesCities, (data) => data.transportTimesCitiesId === props.match.params.id)
      setTransportTimesCity(transportTimesCity)
      getCity().then(res => setCity(res)).catch(error => console.log('error => ', error))
      getTransportTypes().then(res => setTransportType(res.transportTypes)).catch(err => console.log('err => ', err))
    }).catch(error => setError(error))
  }, [])

  const handleAddForm = async (data) => {
    let object = {
      "transportTypeId": data.transportTypeId,
      "cityId": data.cityId,
      "transportTime": data.transportTime
    }
    await addTransportTimesCity(object).then(res => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/transportTimePerCity")
    }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
  }

  const handleEditForm = async (data) => {
    const object = {
      "transportTimesCitiesId": props.match.params.id,
      "transportTypeId": data.transportTypeId,
      "transportTime": data.transportTime
    }
    await updateTransportTimesCity(object).then(res => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/transportTimePerCity")
    }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
  }
  const validation = (data) => {
    let errors = {}
    if (!data.cityId || data.cityId === "") {
      errors.cityId = "Required";
    }
    if (!data.transportTypeId || data.transportTypeId === "") {
      errors.transportTypeId = "Required";
    }
    if (!data.transportTime || data.transportTime === "") {
      errors.transportTime = "Required";
    }
    return errors
  }
  return (
    <div className="main-content">
      <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/transportTimePerCity"><img src={BackIcon} alt="BackIcon" /></Link><span>{heading}</span></div>
      </div>
      <div className="card p-3">
        <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={values} validate={validation}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="cityId" component="select" options={city && city}>
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <lable>{renderFormatMsg("CityName", "City Name")}</lable>
                            <select className="form-control" style={selectStyle(input)} name={get(options, "name")} onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select City</option>
                              {options && options.map((x, i) =>
                                <option selected={input.value === x.name} key={i} value={x.cityId}>
                                  {x.name}</option>)}
                            </select>
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        }
                      </Field>
                    </div>
                    <div className="col-lg-6">
                      <Field name="transportTypeId" component="select" options={transportType && transportType}>
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <lable> {renderFormatMsg("TransportType", "Transport Type")}</lable>
                            <select className="form-control" style={selectStyle(input)} name={get(options, "name")} onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select Type</option>
                              {options && options.map((x, i) =>
                                <option selected={input.value === x.transportTypeId} key={i} value={x.transportTypeId}>{x.name}</option>)}
                            </select>
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        }
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <Field name="transportTime">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <lable> {renderFormatMsg("TransportTime", "Transport Time")} </lable>
                            <input {...input} type="number" placeholder="Transport Time" className="form-control" />
                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div >
  )
}

export default React.memo(HandleTransportTimePerCity)
