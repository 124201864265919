export const HOST_API = "https://repo.commercial-lab.com"

export const NOTIFICATIONS_INTERVAL = 600000;
export const PAGE_SIZE = 10;

export const SEVERITY_SELECT_OPTION = [
  {
    id: "notificationTable.SeverityHigh",
    label: "High",
    value: 1
  }, {
    id: "notificationTable.SeverityMedium",
    label: "Medium",
    value: 2
  }, {
    id: "notificationTable.SeverityLow",
    label: "Low",
    value: 3
  }, {
    id: "notificationTable.SeverityIgnore",
    label: "Ignore",
    value: 4
  },
]

export const SHORT_DATE_FORMAT_OPTIONS = [
  {
    label: "DD.MM.YYYY",
    value: "DD.MM.YYYY"
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY"
  }
]

export const LONG_DATE_FORMAT_OPTIONS = [
  {
    label: "dd.MM.YYYY HH:mm am/pm",
    value: "DD.MM.YYYY HH:mm a"
  },
  {
    label: "MM/DD/YYYY HH:mm am/pm",
    value: "MM/DD/YYYY HH:mm a"
  }
]

export const LANGUAGES = [
  {
    label: "English",
    value: "en"
  },
  {
    label: "German",
    value: "de"
  },
  {
    label: "Russian",
    value: "ru"
  }
]
export const TRANSPORT_TIME_CITY_SELECT_OPTION = [
  {
    id: "transportTimePerCityTable.TransportType_1",
    label: "Type 1",
    value: "Type 1"
  }, {
    id: "transportTimePerCityTable.TransportType_2",
    label: "Type 2",
    value: "Type 2"
  }, {
    id: "transportTimePerCityTable.TransportType_3",
    label: "Type 3",
    value: "Type 3"
  }
]

export const DATE_FORMAT = "YYYY-M-D";
export const DEFAULT_DATE_FORMAT = "D.M.YYYY";
export const SIZE_BOWL = [
  {
    id: "1",
    label: "XXS",
    value: "XXS"
  }, {
    id: "2",
    label: "XS",
    value: "XS"
  }, {
    id: "3",
    label: "S",
    value: "S"
  },
  {
    id: "4",
    label: "M",
    value: "M"
  },
  {
    id: "5",
    label: "L",
    value: "L"
  },
  {
    id: "6",
    label: "XL",
    value: "XL"
  }, {
    id: "7",
    label: "XXL",
    value: "XXL"
  },
]

export const COUNTRY_LIST = [
  { id: "Belgium", name: "Belgium" },
  { id: "France", name: "France" },
  { id: "Germany", name: "Germany" },
  { id: "Italy", name: "Italy" },
  { id: "Portugal", name: "Portugal" },
  { id: "Russia", name: "Russia" },
  { id: "Spain", name: "Spain" },
  { id: "United Kingdom", name: "United Kingdom" },
  { id: "United States", name: "United States" }
]
export const INTERACTION_CODE_LIST = [
  { id: "Independant", name: "Independant", value: "Independant" },
  { id: "Dependant", name: "Dependant", value: "Dependant" },
]

export const PAGE_ACCESS_FUNCTION = {
  USER: "52322d02-68b5-4934-9ee2-007236411704",
  OUTLET: "8c434c19-96f5-475b-b8b4-70b120276933",
  SESSION: "8a147f5a-f501-43a1-94ad-78a7ec836494",
  SETTINGS: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
  PROMOTION: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
  USER_ROLES: "bb684e41-a280-4c21-a753-236a7efa96f6",
  WAREHOUSES: "6e841f7c-343e-4537-a7d0-487291fd1a07",
  PROMOTIONS: "6d66af5f-6a97-45eb-9a98-d29aa2c7c17d",
  CLIENT_PAGE: "e4ed8613-be81-4c0d-b768-39fd3a7ea327",
  MANUAL_ORDER: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  VIEW_OVERVIEW: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
  STORE_CATEGORY: "187280a3-c1ce-4073-910c-dc2941eb1e92",
  PROMOTION_TYPES: "eb50a708-1403-4da2-b75e-8dd27675725b",
  PRODUCT_SETTING: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  PRODUCT_BLOCKING: "d9bdc283-73be-4f0b-9467-755cc420cc3b",
  PRODUCT_CATEGORIES: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
  PRODUCT_DISTRIBUTION: "64bd67e7-07eb-4383-8451-9896d514afbf",
  REPLENISHMENT_CALENDAR: "0bb332cb-7e84-4ef7-bc76-52beb8a613bc",
  TRANSPORT_TIME_PER_CITY: "72e9e2e0-a90f-4a3c-b00c-5ed47749be30",
  TRANSPORT_TIME_PER_STORE: "14371ecb-68d8-4a58-99bc-7d5db1379097",
  AASIGN_MASTER_USER_FLAGE: "eb50a708-1403-4aa2-b75e-8dd27675725b",
  REPLENISHMENT_ORDER_OVERVIEW: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
  EDIT_PRODUCT_SETTINGS_STEERING_PARAMETER: "770cf10a-7d92-4ce9-9dad-5cce146151ea",
}

export const FIELD_ACCESS_FUNCTION = {
  OUTLET_FORM_CATEGORY_1: "1a602548-a965-4a94-b9de-239405c96430",
  OUTLET_FORM_CATEGORY_2: "1a07e5e1-7152-4cc9-ac69-6607e53305b5",

  PRODUCT_FORM_CATEGORY_1: "c458191f-85ee-4c9f-b4cd-88270b25344b",
  PRODUCT_FORM_CATEGORY_2: "770cf10a-7d92-4ce9-9dad-5cce146151ea",

  PRODUCT_CATEGORIES_FORM_CATEGORY_1: "15b63a2b-c92c-4d6c-a975-2e2052c4ed5a",
  PRODUCT_CATEGORIES_FORM_CATEGORY_2: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
  SEASON_FORM_CATEGORY_1: "8a147f5a-f501-43a1-94ad-78a7ec836494",
  SEASON_FORM_CATEGORY_2: "0c845ef2-e76d-4b79-badd-cbc4738a4dcd"
}

export const SIDEBAR_MENU_ACCESS_FUNCTION = {
  PROMOTION: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
  SPECIAL_REPLENISHMENT: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  MANUAL_ORDER: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  EDIT_PRODUCT_SETTING: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  PRODUCTS: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  REPLENISHMENT_ORDER_OVERVIEW: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
  RECOMMENDATIONS: "e9b298e8-c510-4239-9371-7ff5923464d3",
  SETTINGS: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
  OVERVIEW: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
}

export const PERMISSION_LEVEL_READ_ONLY = "RO"
export const PERMISSION_LEVEL_READ_WRITE = "RW"
