
import { get, put, post, deleted } from "./index";

// Repl3nishment page APIs
export const getspecialReplenishment = async (params) => {
  return await get('api/v1/Replenishment/specialReplenishment', params);
};

export const updatespecialReplenishment = async (data) => {
  return await put('api/v1/Replenishment/UpdateSpecialReplenishment', data);
};

export const getDashboadData = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=discSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};

export const getDashboadDataForSaleUnit = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=unitSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};

export const getDashboadDataForSalePrice = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=priceSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};

export const getDashboadDataForRealizedMargin = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};


export const getDashboadDataForSellOut = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};
export const getDashboadDataForRealOOS = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};
export const getDashboadDataForSpeedOfSales = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};
export const getDashboadDataForCoverage = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};
export const getDashboadDataForAbsoluteOOS = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};
export const getDashboadDataForDiscountedProducts = async ({ dateRange = "week", brandId = null }) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`
  }
  return await get(URL)
};


// Replanihsmnet Order overview API
export const getReplanishmentOrder = async (params) => {
  return await get(`api/v1/Replenishment/orderOverview`, params)
};
export const getReplanishmentOrderSku = async (params) => {
  return await get(`api/v1/Replenishment/orderSKUs`, params)
};
export const getReplanishmentOrderDetail = async (params) => {
  return await get(`api/v1/Replenishment/OrderDetails`, params)
};

export const getReplanishmentQuantities = async (params) => {
  return await get(`api/v1/Replenishment/OrderQuantity`, params)
};

export const getPerformance = async (params) => {
  return await get(`api/v1/option/performance`, params)
};

//ProductDistribution page APIs
export const getOptions = async (params) => {
  return await get(`api/v1/Option/options`, params)
};
export const getProductDistributionOverview = async (params) => {
  return await get(`api/v1/option/distribution`, params)
};
export const getProductDistributionDetail = async (params) => {
  return await get(`api/v1/option/DistributionDetail`, params)
};

export const updateProductDistributionDetail = async (updatingData) => {
  return await put(`api/v1/Outlet/UpdateOutletOptionsBlock`, updatingData)
};

export const updateOptions = async (addData) => {
  return await put(`api/v1/Option/UpdateOption`, addData)
};
export const getOptionCategory = async (id) => {
  return await get(`api/v1/Option/Category?categoryId=${id}`)
};
//Manual order page API
export const getManualOrderDtail = async (params) => {
  return await get(`api/v1/Order/orders`, params)
};
export const getSkusData = async (params) => {
  return await get(`api/v1/SKU/skus`, params)
};
export const addManualOrder = async (params) => {
  return await post(`api/v1/Order/ManualOrder`, params)
};
export const updateManualOrder = async (params) => {
  return await put(`api/v1/Order/ManualOrder`, params)
};

export const deleteManualOrder = async (key, id) => {
  return await deleted(`api/v1/Order?${key}=${id}`)
};

//Promotion API
export const getPromotion = async (params) => {
  return await get(`api/v1/Promotion/promotions`, params)
};
export const updatePromotion = async (params) => {
  return await put(`api/v1/Promotion/Promotion`, params)
};
export const addPromotion = async (params) => {
  return await post(`api/v1/Promotion/Promotion`, params)
};
export const deletePromotion = async (key, id) => {
  return await deleted(`api/v1/Promotion/?${key}=${id}`)
};
