import { get, post, put, deleted } from "./index";

// Repl3nishment Calendar page APIs
export const getOutletCalendars = async (params) => {
    return await get('api/v1/Outlet/outletCalendars', params);
};

export const updateOutletCalendar = async (data) => {
    return await post('api/v1/Outlet/addUpdateOutletCalendar', data);
};

//Store page APIs

export const getOutletCategories = async (params) => {
    return await get('api/v1/Outlet/outletCategories', params);
};

export const updateOutletCategories = async (data) => {
    return await put('api/v1/Outlet/UpdateOutletCategory', data);

};
export const addOutletCategories = async (data) => {
    return await post(`api/v1/Outlet/AddOutletCategory`, data);
};

export const deleteOutletCategory = async (key, id) => {
    return await deleted(`api/v1/Outlet?${key}=${id}`)
};
//Season page APIs
export const getSeasonsDetails = async (params) => {
    return await get(`api/v1/Season/seasons`, params)
};
export const updateSeasonsDetails = async (data) => {
    return await put(`api/v1/Season/UpdateSeason`, data)
};

export const addNewSeason = async (data) => {
    return await post(`api/v1/Season/AddSeason`, data);
};

//outlet page APIs
export const getOutletData = async (params) => {
    return await get(`api/v1/Outlet/outlets`, params)
};
export const addOutletData = async (data) => {
    return await post(`api/v1/Outlet/AddOutlet`, data);
};
export const updateOutletData = async (data) => {
    return await put(`api/v1/Outlet/UpdateOutlet`, data)
};

//User Page APIs
export const getUserData = async (params) => {
    return await get(`api/v1/Admin/users`, params)
};

//get user's roles
export const getUserRoles = async (params) => {
    return await get(`api/v1/admin/userroles`, params)
};

export const addNewUser = async (data) => {
    return await post(`api/v1/Admin/AddUser`, data);
};
export const updateUser = async (updatingData) => {
    return await put(`api/v1/Admin/UpdateUser`, updatingData)
};
//Roles page APIs
export const getClientRolesData = async (params) => {
    return await get(`api/v1/client/ClientRoles`, params)
};
export const updateUserRoles = async (updatingData) => {
    return await put(`api/v1/Client/UpdateClientRole`, updatingData)
};
export const addUserRoles = async (addingData) => {
    return await post(`api/v1/Client/AddClientRole`, addingData);
};
export const deleteUserRoles = async (id) => {
    return await deleted(`api/v1/Client/clientRole/${id}`)
};
//transportTimesCities page APIs
export const getTransportTimesCities = async (params) => {
    return await get(`api/v1/Transport/transportTimesCities`, params)
};

export const updateTransportTimesCity = async (params) => {
    return await put(`api/v1/Transport/UpdateTransportTimesCity`, params)
};
export const addTransportTimesCity = async (data) => {
    return await post(`api/v1/Transport/AddTransportTimesCity`, data)
};
export const deleteTransportTimesCity = async (id) => {
    return await deleted(`api/v1/Transport/transportTimesCity/${id}`)
};

export const getTransportTypes = async (params) => {
    return await get(`api/v1/Transport/transportTypes`, params)
};
//client page APIs
export const getClientData = async (params) => {
    return await get(`api/v1/Client/clients`, params)
};

export const AddNewClient = async (updatingData) => {
    return await post(`api/v1/Client/AddClient`, updatingData);
};

export const updateClientData = async (data) => {
    return await put(`api/v1/Client/UpdateClient`, data)
};

//Warehouse page APIs
export const getWarehouseData = async (params) => {
    return await get(`api/v1/Warehouse/warehouses`, params)
};

export const updateWarehouseData = async (data) => {
    return await put(`api/v1/Warehouse/UpdateWarehouse`, data)
};
export const AddNewWarehouse = async (updatingData) => {
    return await post(`api/v1/Warehouse/AddWarehouse`, updatingData);

};
//userRoleAssign Page
export const getUserRoleAssignData = async (params) => {
    return await get(`api/v1/client/clientRoles`, params)
};
export const updateUserRoleAssignment = async (updatingData) => {
    return await put(`api/v1/Admin/UpdateUserRole`, updatingData)
};

// Product Category API
export const getProductCategory = async () => {
    return await get(`api/v1/option/category`)
};
export const getProductSizeName = async (params) => {
    return await get(`api/v1/Option/Size`, params)
};
export const updateProductCategory = async (updatingData) => {
    return await put(`api/v1/Option/UpdateOptionCategory`, updatingData)
};
export const addProductCategory = async (addingData) => {
    return await post(`api/v1/Option/AddOptionCategory`, addingData);
};
export const deleteProductCategory = async (key, id) => {
    return await deleted(`api/v1/Option?${key}=${id}`)
};
// /api/v1/City
export const getCity = async (params) => {
    return await get(`api/v1/City`, params)
};
export const getBrand = async () => {
    return await get(`api/v1/Brand/brand`)
};

//Promotion Type API
export const getPromotionType = async (params) => {
    return await get(`api/v1/Promotion/promotionTypes`, params)
};
export const updatePromotionType = async (params) => {
    return await put(`api/v1/Promotion/PromotionType`, params)
};
export const addPromotionType = async (params) => {
    return await post(`api/v1/Promotion/PromotionType`, params)
};
export const deletePromotionType = async (key, id) => {
    return await deleted(`api/v1/Promotion/DeletePromotionType?${key}=${id}`)
};
