import React, { lazy } from "react";
import { Route } from "react-router-dom";
import HandleStoreCategory from "./view/ToolsAndSettings/UpdatingPage/HandleStoreCategory";
import HandleClientPage from "./view/ToolsAndSettings/UpdatingPage/HandleClientPage";
import ManualOrder from "./view/ManualOrder/ManualOrder";
import AddEditManualOrder from "./view/ManualOrder/AddEditManualOrder";
import Promotion from "./view/Promotion/Promotion";
import PromotionType from "./view/PromotionType/PromotionType";
import HandlePromotionType from "./view/PromotionType/HandlePromotionType";
import HandlePromotion from "./view/Promotion/HandlePromotion";
import HandleTransportTimePerCity from "./view/ToolsAndSettings/UpdatingPage/HandleTransportTimePerCity";

const Notifications = lazy(() => import("./view/NotificationSettings/NotificationSettings"));
const Overview = lazy(() => import("./view/OverView/Overview"));
const ReplenishmentOrdersOverview = lazy(() => import("./view/ReplenishmentOrdersOverview/index"));
const ReplenishmentOrderSku = lazy(() => import("./view/ReplenishmentOrdersOverview/ReplanishmentOrderSku"));
const ReplenishmentOrderDetail = lazy(() => import("./view/ReplenishmentOrdersOverview/ReplanishmentOrderDetail"));
const ReplanishmentQuantities = lazy(() => import("./view/ReplenishmentOrdersOverview/ReplanishmentQuantities"));
const ReplenishmentCalendar = lazy(() => import("./view/ToolsAndSettings/ReplenishmentCalendar"));
const StoreCategory = lazy(() => import("./view/ToolsAndSettings/StoreCategory"));
const Layout = lazy(() => import("./components/Layout/Layout"));
const Seasons = lazy(() => import("./view/ToolsAndSettings/Seasons"));
const HandleSeason = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleSeason"));
const Outlet = lazy(() => import("./view/ToolsAndSettings/Outlet"));
const User = lazy(() => import("./view/ToolsAndSettings/User"));
const HandleUser = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleUser"));
const UserRoles = lazy(() => import("./view/ToolsAndSettings/UserRoles"));
const TransportTimePerCity = lazy(() => import("./view/ToolsAndSettings/TransportTimePerCity"));
const ClientPage = lazy(() => import("./view/ToolsAndSettings/ClientPage"));
const EditClient = lazy(() => import("./view/ToolsAndSettings/EditClient"));
const Warehouses = lazy(() => import("./view/ToolsAndSettings/Warehouses"));
const HandleWarehouse = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleWarehouse"));
const UserRoleAssignment = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/UserRoleAssignment"));
const ProductDistributionOverview = lazy(() => import("./view/Products/ProductDistributionOverview"));
const ProductDistributionDetail = lazy(() => import("./view/Products/ProductDistributionDetail"));
const ProductSettings = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/ProductSettings"));
const ProductCategory = lazy(() => import("./view/ToolsAndSettings/ProductCategory"));
const HandleProductCategory = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleProductCategory"));
const HandleOutlet = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleOutlet"));
const HandleUserRoles = lazy(() => import("./view/ToolsAndSettings/UpdatingPage/HandleUserRoles"));
const SpecialReplenishment = lazy(() => import("./view/SpecialReplenishment/index"));
const Settings = lazy(() => import("./view/Settings"));
const ProductPerformance = lazy(() => import("./view/ProductPerformance"));

export const routesCode = [
    { path: "/", exact: true, component: Overview },
    { path: "/notifications", exact: true, component: Notifications },
    { path: "/overview", exact: true, component: Overview },
    { path: "/orders", exact: true, component: ReplenishmentOrdersOverview },
    { path: "/orders/sku/exports/:exportPackageId", exact: true, component: ReplenishmentOrderSku },
    { path: "/orders/sku/exports/:exportPackageId/options/:optionId", exact: true, component: ReplenishmentOrderDetail },
    { path: "/orders/sku/exports/:exportPackageId/options/:optionId/outletId/:outletId/quantity", exact: true, component: ReplanishmentQuantities },
    { path: "/replenishment_calendar", exact: true, component: ReplenishmentCalendar },
    { path: "/storeCategory", exact: true, component: StoreCategory },
    { path: "/seasons", exact: true, component: Seasons },
    { path: "/seasons/Edit/:seasonId", exact: true, component: HandleSeason },
    { path: "/seasons/Add", exact: true, component: HandleSeason },
    { path: "/outlet", exact: true, component: Outlet },
    { path: "/user", exact: true, component: User },
    { path: "/user/Edit/:userId", exact: true, component: HandleUser },
    { path: "/user/Add", exact: true, component: HandleUser },
    { path: "/userRoles", exact: true, component: UserRoles },
    { path: "/transportTimePerCity", exact: true, component: TransportTimePerCity },
    { path: "/warehouses", exact: true, component: Warehouses },
    { path: "/warehouses/Add", exact: true, component: HandleWarehouse },
    { path: "/warehouses/Edit/:warehouseId", exact: true, component: HandleWarehouse },
    { path: "/client", exact: true, component: ClientPage },
    { path: "/user/userRoleAssignment/:userId", exact: true, component: UserRoleAssignment },
    { path: "/optionDistribution", exact: true, component: ProductDistributionOverview },
    { path: "/optionDistribution/optionDistributionDetail/:optionId/:seasonId?", exact: true, component: ProductDistributionDetail },
    { path: "/optionDistribution/productSetting/:optionId", exact: true, component: ProductSettings },
    { path: "/productCategory", exact: true, component: ProductCategory },
    { path: "/specialReplenishment", exact: true, component: SpecialReplenishment },
    { path: "/productCategory/Add", exact: true, component: HandleProductCategory },
    { path: "/productCategory/Edit/:categoryId", exact: true, component: HandleProductCategory },
    { path: "/outlet/Add", exact: true, component: HandleOutlet },
    { path: "/outlet/Edit/:outletId", exact: true, component: HandleOutlet },
    { path: "/userRoles/Add", exact: true, component: HandleUserRoles },
    { path: "/userRoles/Edit/:roleId", exact: true, component: HandleUserRoles },
    { path: "/storeCategory/Add", exact: true, component: HandleStoreCategory },
    { path: "/storeCategory/Edit/:outletCategoryId", exact: true, component: HandleStoreCategory },
    { path: "/client/Add", exact: true, component: HandleClientPage },
    { path: "/client/Edit/:clientId", exact: true, component: EditClient },
    { path: "/manualOrder", exact: true, component: ManualOrder },
    { path: "/manualOrder/Add", exact: true, component: AddEditManualOrder },
    { path: "/manualOrder/Edit/:orderId", exact: true, component: AddEditManualOrder },
    { path: "/promotion", exact: true, component: Promotion },
    { path: "/settings", exact: true, component: Settings },
    { path: "/product-performance/:optionId", exact: true, component: ProductPerformance },
    { path: "/promotion/Edit/:promotionId", exact: true, component: HandlePromotion },
    { path: "/promotion/Add", exact: true, component: HandlePromotion },
    { path: "/promotionType", exact: true, component: PromotionType },
    { path: "/promotionType/Edit/:promotionTypeId", exact: true, component: HandlePromotionType },
    { path: "/promotionType/Add", exact: true, component: HandlePromotionType },
    { path: "/transportTimePerCity/Add", exact: true, component: HandleTransportTimePerCity },
    { path: "/transportTimePerCity/Edit/:id", exact: true, component: HandleTransportTimePerCity },
];

class Routes extends React.PureComponent {
    render() {
        return (
            <Layout>
                {routesCode.map((route, i) =>
                    <Route {...route} key={i} />
                )
                }
            </Layout>
        );
    }
}

export default Routes;
