import React from "react"
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import "../components/ReactTable/Table.scss"
import moment from "moment"

const DatePickerCreater = ({ className = "", placeholderText, selectedDate = new Date(), onSelect, minDate, maxDate, ...rest }) => {
    return (
        <DatePicker
            {...rest}
            className={`form-control ${className}`}
            dateFormat="dd.MM.yyyy"
            selected={moment(selectedDate).isValid() && new Date(selectedDate)}
            onSelect={onSelect}
            minDate={minDate}
            maxDate={maxDate}
            popperModifiers={{
                offset: { enabled: true, offset: '5px, 10px' }
            }}
            placeholderText={placeholderText}
        />
    )
}
export default React.memo(DatePickerCreater);
