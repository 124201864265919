import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import { get } from "lodash"
import { getManualOrderDtail, deleteManualOrder } from '../../services/sidebarApi';
import { getSeasonsDetails } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, PAGE_ACCESS_FUNCTION, DEFAULT_DATE_FORMAT } from '../../constant';
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, checkPermission, toastSuccess, toastError, permissionErrorCheck } from '../../shared/funs';
import Error from "../../components/Error/Error";
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import moment from 'moment';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';

const ManualOrder = () => {
    const [manualOrders, setManualOrders] = useState([])
    const [seasons, setSeasons] = useState([])
    const [selectedSeason, setSelectedSeason] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [deletedId, setDeletedId] = useState(null)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);




    const { userDetails } = useContext(AppContext);
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false });
    let dataArray = get(userDetails, "accesses");

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, PAGE_ACCESS_FUNCTION.MANUAL_ORDER))
    }, [dataArray])


    useEffect(() => {
        getSeasonsDetails().then(res => setSeasons(res.seasons)).catch(err => console.log('err => ', err))
    }, [])

    const getData = ({ page = 0, search = "", seasonId = false }) => {
        setSearchValue(search)
        setLoading(true)
        let param
        seasonId !== false ? param = { "page": page + 1, "display": PAGE_SIZE, "search": search, seasonId: seasonId } : param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
        getManualOrderDtail(param).then(res => {
            setManualOrders(res.orders)
            setTotalPage(res.totalPage)
            setLoading(false)
        }).catch(err => { setLoading(false); setError(permissionErrorCheck(err)) })
    }
    const columns = [{
        Header: <div style={{ textAlign: "left" }}>{renderFormatMsg("StoreName", "Store Name")} </div>,
        accessor: 'outletName',
        resizable: false,
        minwidth: 200,
        style: { justifyContent: "flex-start" },
    },
    {
        Header: renderFormatMsg("OrderDate", "Order Date"),
        accessor: 'date',
        width: 110,
        resizable: false,
        Cell: props => moment(props.original.date).format(DEFAULT_DATE_FORMAT),
        style: { justifyContent: "flex-start" }
    }, {
        Header: <div style={{ textAlign: "left" }}>{renderFormatMsg("OrderDescription", "Order Description")}</div>,
        accessor: 'description',
        width: 200,
        resizable: false,
        style: { justifyContent: "flex-start" },
    }, {
        Header: renderFormatMsg("NumberProducts", "Number Products"),
        accessor: 'totalItems',
        width: 150,
        resizable: false,
        style: { justifyContent: "flex-start" },
    },]


    if (pageReadOnly.write) {
        columns.push({
            Header: pageReadOnly.write ? renderFormatMsg("Edit", "Edit") : "",
            accessor: 'Edit',
            resizable: false,
            width: 100,
            Cell: props => pageReadOnly.write ? <Link to={`/manualOrder/Edit/${props.original.orderId}`} className="edit-icon" >{renderFormatMsg("Edit", "Edit")}</Link> : ""
        }, {
            Header: pageReadOnly.write ? renderFormatMsg("Delete", "Delete") : "",
            accessor: 'Delete',
            resizable: false,
            width: 100,
            Cell: props => pageReadOnly.write ? <Link to="#" className="delete-icon" onClick={() => { handleClose(); setDeletedId(props.original.orderId) }}>{renderFormatMsg("Delete", "Delete")}</Link> : ""
        })
    }

    const fetchData = (state, instance) => {
        //getting change page number from state
        getData({ page: state.page, search: searchValue })
        setPageIndex(state.page)
    }
    const handleDelete = async () => {
        await deleteManualOrder("orderId", deletedId).then(async res => {
            await getData({ page: 0, search: "" })
            handleClose()
            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
    }
    const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }) }
    const handleSeasonData = (data) => {
        setSelectedSeason(data)
        getData({ seasonId: data.seasonId })
    }
    return (
        <div className="main-content">
            <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("manualOrder.Heading", "Manual Order")}
                {!error && <div className="right-content select-box ml-auto">
                    <DropDownCustom name="name" selectedData={(selectedSeason && selectedSeason.name) || "Season"} dropdownData={seasons} handleData={handleSeasonData} />
                </div>}
            </div>
            <div className="card">
                {error ? <Error msg={error} /> :
                    <>
                        <div className="card-header">
                            <SearchBox searchHandler={SearchHandler} />
                            {pageReadOnly.write && <LinkButton disabled={!pageReadOnly.write} classes={"ml-auto"} to="/manualOrder/Add" id="AddOrder" defaultText="Add Order" />}
                        </div>
                        <ReactTable data={manualOrders} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
                    </>
                }
            </div>
            <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
        </div>
    )
}
export default React.memo(ManualOrder)
