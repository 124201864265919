import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastSuccess, toastError } from '../../shared/funs';
import { Link } from 'react-router-dom';
import BackIcon from "../../images/back-icon.svg";
import { updatePromotionType, addPromotionType, getPromotionType } from '../../services/toolsAndSettingsPagesApi';

const HandlePromotionType = (props) => {
  const [promotionType, setPromotionType] = useState(null)
  // eslint-disable-next-line
  const [error, setError] = useState("")

  var heading = renderFormatMsg("AddPromotionType", "Add Promotion Type");
  var values = {
    name: "",
    coefficient: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = renderFormatMsg("EditPromotionType.Heading", "Edit Promotion Type");
    values = {
      name: get(promotionType, "name"),
      coefficient: get(promotionType, "coefficient")
    }
  }

  useEffect(() => {
    getPromotionType().then(res => {
      let promotionType = find(res.promotionTypes, (data) => data.promotionTypeId === props.match.params.promotionTypeId);
      setPromotionType(promotionType)
    }).catch(err => setError(err))
  }, [])

  const handleAddForm = async (data) => {
    let object = {
      "name": data.name,
      "coefficient": data.coefficient
    }
    await addPromotionType(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/promotionType")
    }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
  }

  const handleEditForm = async (data) => {
    const object = {
      "promotionTypeId": props.match.params.promotionTypeId,
      "name": data.name,
      "coefficient": data.coefficient
    }

    updatePromotionType(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/promotionType")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }

  const validation = (data) => {
    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    var patt = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
    if (data && typeof data.coefficient !== "undefined") {
      if (patt.test(data.coefficient)) {
        errors.coefficient = undefined;
      } else {
        errors.coefficient = "Invalid Input"
      }
    } else {
      errors.coefficient = "Required"
    }
    return errors
  }
  return (
    <div className="main-content">
      <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/promotionType"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div>
      <div className="card p-3">
        <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={values} validate={(data) => validation(data)}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <div className="col-12 col-lg">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group mb-0">
                            <lable> {renderFormatMsg("PromotionTypeName", "Promotion Type Name")} </lable>
                            <input {...input} type="text" placeholder="Promotion Type Name" className="form-control" />
                            {meta.error && meta.touched && (<span>{meta.error}</span>)}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-12 col-lg">
                      <Field name="coefficient">
                        {({ input, meta }) =>
                          <div className="form-group mb-0">
                            <lable> {renderFormatMsg("BaseCoefficient", "Base Coefficient")}</lable> <input type="text" {...input} placeholder="Base Coefficient" className="form-control" />
                            {meta.error && meta.touched && <span>{meta.error}</span>}
                          </div>
                        }
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-end mt-2">
                  <div className="col-12 col-lg-auto text-right">
                    <div className="submit_btn">
                      <button type="submit" className="btn btn-primary" > {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div>
  )
}
export default React.memo(HandlePromotionType)
