import React, { useContext, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route } from "react-router-dom";
import Routes from "./routes";
import "./App.scss";
import { IntlProvider } from "react-intl";
import { AppContext } from "./context/AppContext";

const Login = lazy(() => import('./view/Login/Login'));

const App = ({ messages }) => {
  const { language } = useContext(AppContext);
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Routes />
        </Switch>
      </Suspense>
    </IntlProvider>
  );
}

export default React.memo(App);
