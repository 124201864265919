
import React from 'react'
import ReactDTable from "react-table";
import "react-table/react-table.css";
import "./Table.scss"
import { PAGE_SIZE } from '../../constant';
const ReactTable = (props) => {
    const { columns, data, className, isLoading = false, fetchData, ...rest } = props;
    return (
        <div className={`card-body`}>
            <ReactDTable columns={columns}
                data={data}
                manual
                loading={isLoading}
                defaultPageSize={PAGE_SIZE}
                showPageSizeOptions={false}
                className={'striped'}
                {...rest} />
        </div>
    )

}
export default React.memo(ReactTable);
